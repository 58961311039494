import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomImageComponent } from '../custom-image/custom-image.component';
import { StorageService } from '@core/services/storage.service';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-custom-avatar',
  standalone: true,
  imports: [CommonModule, CustomImageComponent, ImageModule, CustomImageComponent],
  templateUrl: './custom-avatar.component.html',
  styleUrl: './custom-avatar.component.scss'
})
export class CustomAvatarComponent implements OnChanges {
  @Input() size: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | '2lg' | 'xl' | '2xl' | '3xl'| 'customSize' = 'xs';
  @Input() imgId?: string = '';
  @Input() name = '';
  @Input() preview: boolean = false;

  readonly environment = environment;

  backgroundColor: string = '';
  textColor: string = '';
  initials: string = '';

  constructor(private storageService: StorageService) {}

  ngOnChanges(): void {
    this.initials = this.getInitials(this.name);
    this.backgroundColor = this.getColorFromName(this.name);
    this.textColor = this.getContrastingColor(this.backgroundColor);
  }

  private getInitials(name: string): string {
    if (!name) return '';
    if (this.size === 'xs' || this.size === '2xs') return name.charAt(0);
    const nameParts = name.split(' ');
    if (nameParts.length > 1) {
      return nameParts[0].charAt(0) + nameParts[1].charAt(0);
    } else {
      return name.charAt(0);
    }
  }

  private hashCode(str: string): number {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private intToRGB(i: number): string {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
  }

  private getColorFromName(name: string): string {
    const hash = this.hashCode(name);
    const color = this.intToRGB(hash);
    return `#${color}`;
  }

  private getContrastingColor(color: string): string {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? 'black' : 'white';
  }

  getSrcImg(imgId: string) {
    return this.storageService.getFileUrl(imgId);
  }
}
